import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Ajoutez vos traductions ici
const resources = {
  fr: {
    translation: {
      welcome: "Bienvenue \u200B <br /> sur MyBusiness-bnb",
      chooseLanguage: "Quelle langue parlez vous ?",
      french: "Français",
      english: "Anglais",
      spanish: "Espagnol",
      german: "Allemand",
      areYouTourist: "Etes-vous un touriste ",
      frenchOption: "Français",
      foreignerOption: "Etranger",
      selectedOption: "Option sélectionnée :",
      conciergeInfo: `
        <div>
        Nous utilisons cette application pour faciliter la gestion de nos locations.
        </div>
      `,
      conciergeInfo2: "Veuillez vérifier l'exactitude des informations.",
      conciergeInfo3: "Et merci de préciser vos heures d'arrivée et de départ.",
      houseName: "Nom de l'hébergement :",
      reservationDates: "Dates de réservations :",
      months: {
        1: "Janv.",
        2: "Fév.",
        3: "Mars",
        4: "Avr.",
        5: "Mai",
        6: "Juin",
        7: "Juil.",
        8: "Août",
        9: "Sept.",
        10: "Oct.",
        11: "Nov.",
        12: "Déc.",
      },
      from: "Du",
      to: "Au",
      selectBothHours:
        "Veuillez sélectionner à la fois les heures d'arrivée et de départ.",
      specifyArrivalDeparture: "Précisez vos heures d'arrivée et de départ.",
      arrivalHour: "Heure d'arrivée",
      departureHour: "Heure de départ",
      numberOfBeds: "Nombre de lits",
      selectBeds: "Sélectionnez le nombre de lits",
      selectBedsError: "Veuillez sélectionner le nombre de lits.",
      requiredInformation: "Informations requises pour votre séjour en France",
      foreignTravelersInfo:
        "En tant que voyageurs étrangers en visite en France, il est impératif de compléter une 'fiche de police' contenant vos informations personnelles. En cas de non-renseignement de ces informations, l'accès à l'hébergement réservé vous sera malheureusement refusé.",
      personalInfo: "Informations personnelles",
      firstName: "Prénom",
      lastName: "Nom",
      birthDate: "Date de naissance",
      birthDateFormat: "jj.mm.aaaa",
      placeOfBirth: "Lieu de naissance",
      nationality: "Nationalité",
      personalAddress: "Votre adresse personnelle",
      country: "Pays",
      streetAddress: "Adresse postale",
      apartmentBuilding: "Appartement, bâtiment",
      postalCode: "Code Postal",
      city: "Ville",
      communicationMethod: "Moyen de communication",
      phone: "Téléphone",
      email: "Email",
      invalidEmail: "Veuillez saisir une adresse email valide.",
      informationTransmission:
        "Ces renseignements nous permettront de vous transmettre les directives nécessaires à votre arrivée sur place.",
      myBusinessBnbGuide:
        "MyBusiness-bnb l'application qui vous guidera pendant votre séjour.",
      appDescription:
        "Cette application facilite la communication entre hôtes et voyageurs tout au long du séjour.",
      address: "Adresse",
      selfCheckIn: "Arrivée autonome",
      contacts: "Contacts",
      wifiCode: "Code Wifi",
      trash: "Poubelle",
      selfCheckOut: "Départ autonome",
      preRegistrationSuccess:
        "Votre processus de pré-enregistrement est maintenant paramétré !",
      registrationComplete:
        "Vous venez de terminer le pré-enregistrement !<br /><br />Un message contenant le lien de votre application voyageur vous sera envoyé.<br /><br />Prenez le temps de le consulter afin de vous familiariser avec les détails et de vous préparer pour votre arrivée.<br /><br />À très bientôt",
      fillAllFields: "Veuillez remplir tous les champs.",
      emailFormatIncorrect: "Le format de l'e-mail est incorrect",
      hello: "Bonjour",
      notices: "Mode d'emploi",
      parking: "Parking",
      goodspots: "Bons Plans",
      confirmAndFinish: "Confirmer et Terminer",
      appOverview:
        "Aperçu de l'application pour accéder à tous nos services et informations pendant votre séjour.",
    },
  },
  gb: {
    translation: {
      welcome: "Welcome \u200B <br /> to MyBusiness-bnb",
      chooseLanguage: "What is your language?",
      french: "French",
      english: "English",
      spanish: "Spanish",
      german: "German",
      areYouTourist: "Are you a tourist",
      frenchOption: "French",
      foreignerOption: "Foreigner",
      selectedOption: "Selected option:",
      conciergeInfo: `
      <div>
      We use this application to facilitate the management of our rentals.
    </div>
    `,
      conciergeInfo2: "Please verify the accuracy of the information.",
      conciergeInfo3:
        "And thank you for specifying your arrival and departure times.",
      houseName: "Accommodation name:",
      reservationDates: "Reservation dates",
      months: {
        1: "Jan.",
        2: "Feb.",
        3: "Mar.",
        4: "Apr.",
        5: "May.",
        6: "Jun.",
        7: "Jul.",
        8: "Aug.",
        9: "Sep.",
        10: "Oct.",
        11: "Nov.",
        12: "Dec.",
      },
      from: "From",
      to: "To",
      selectBothHours: "Please select both arrival and departure hours.",
      specifyArrivalDeparture: "Specify your arrival and departure hours.",
      arrivalHour: "Arrival time",
      departureHour: "Departure time",
      numberOfBeds: "Number of beds",
      selectBeds: "Select Number of Beds",
      selectBedsError: "Please select the number of beds.",
      requiredInformation: "Required Information for Your Stay in France",
      foreignTravelersInfo:
        "As foreign travelers visiting France, it is imperative to complete a 'police form' containing your personal information. Failure to provide this information will unfortunately result in denied access to the reserved accommodation.",
      personalInfo: "Personal Information",
      firstName: "First Name",
      lastName: "Last Name",
      birthDate: "Date of Birth",
      birthDateFormat: "dd.mm.yyyy",
      placeOfBirth: "Place of Birth",
      nationality: "Nationality",
      personalAddress: "Your Personal Address",
      country: "Country",
      streetAddress: "Street Address",
      apartmentBuilding: "Apartment, Building",
      postalCode: "Postal Code",
      city: "City",
      communicationMethod: "Communication Method",
      phone: "Phone",
      email: "Email",
      invalidEmail: "Please enter a valid email address.",
      informationTransmission:
        "This information will allow us to provide you with the necessary instructions for your arrival on site.",
      myBusinessBnbGuide:
        "MyBusiness-bnb The app that will guide you during your stay.",
      appDescription:
        "This application facilitates communication between hosts and travelers throughout the stay.",
      address: "Address",
      selfCheckIn: "Self Check-In",
      contacts: "Contacts",
      wifiCode: "Wifi Code",
      trash: "Trash",
      selfCheckOut: "Self Check-Out",
      preRegistrationSuccess: "Your pre-registration process is now set up!",
      registrationComplete:
        "You have just completed the pre-registration!<br /><br />A message containing the link to your traveler application will be sent to you.<br /><br />Take the time to review it to familiarize yourself with the details and prepare for your arrival.<br /><br />See you soon!",
      fillAllFields: "Please fill in all fields.",
      emailFormatIncorrect: "The email format is incorrect",
      hello: "Hello",
      notices: "User Guide",
      parking: "Parking",
      goodspots: "Good Spots",
      confirmAndFinish: "Confirm and Finish",
      appOverview:
        "Overview of the app to access all our services and information during your stay.",
    },
  },
  es: {
    translation: {
      welcome: "Bienvenido \u200B <br /> a MyBusiness-bnb",
      chooseLanguage: "¿Qué idioma hablas?",
      french: "Francés",
      english: "Inglés",
      spanish: "Español",
      german: "Alemán",
      areYouTourist: "¿Eres turista",
      frenchOption: "Francés",
      foreignerOption: "Extranjero",
      selectedOption: "Opción seleccionada:",
      conciergeInfo: `
      <div>
      Utilizamos esta aplicación para facilitar la gestión de nuestros alquileres.
    </div>
    
      `,
      conciergeInfo2: "Por favor, verifique la exactitud de la información.",
      conciergeInfo3:
        "Y gracias por especificar sus horas de llegada y salida.",
      houseName: "Nombre del alojamiento:",
      reservationDates: "Fechas de reserva:",
      months: {
        1: "Ene.",
        2: "Feb.",
        3: "Mar.",
        4: "Abr.",
        5: "May.",
        6: "Jun.",
        7: "Jul.",
        8: "Ago.",
        9: "Sep.",
        10: "Oct.",
        11: "Nov.",
        12: "Dic.",
      },
      from: "Del",
      to: "Al",
      selectBothHours:
        "Por favor, selecciona tanto la hora de llegada como la de salida.",
      specifyArrivalDeparture: "Especifique sus horas de llegada y salida.",
      arrivalHour: "Hora de llegada",
      departureHour: "Hora de salida",
      numberOfBeds: "Número de camas",
      selectBeds: "Seleccione el número de camas",
      selectBedsError: "Por favor seleccione el número de camas.",
      requiredInformation: "Información necesaria para su estancia en Francia",
      foreignTravelersInfo:
        "Como viajeros extranjeros que visitan Francia, es imperativo completar un 'formulario de policía' con su información personal. En caso de no proporcionar esta información, lamentablemente se denegará el acceso al alojamiento reservado.",
      personalInfo: "Información personal",
      firstName: "Nombre",
      lastName: "Apellido",
      birthDate: "Fecha de Nacimiento",
      birthDateFormat: "dd.mm.aaaa",
      placeOfBirth: "Lugar de Nacimiento",
      nationality: "Nacionalidad",
      personalAddress: "Su dirección personal",
      country: "País",
      streetAddress: "Dirección",
      apartmentBuilding: "Apartamento, Edificio",
      postalCode: "Código Postal",
      city: "Ciudad",
      communicationMethod: "Medio de comunicación",
      phone: "Teléfono",
      email: "Email",
      invalidEmail:
        "Por favor, introduzca una dirección de correo electrónico válida.",
      informationTransmission:
        "Esta información nos permitirá proporcionarle las instrucciones necesarias para su llegada al lugar.",
      myBusinessBnbGuide:
        "MyBusiness-bnb La aplicación que te guiará durante tu estancia.",
      appDescription:
        " Esta aplicación facilita la comunicación entre anfitriones y viajeros durante toda la estadía.",
      address: "Dirección",
      selfCheckIn: "Entrada Autónoma",
      contacts: "Contactos",
      wifiCode: "Código Wifi",
      trash: "Basura",
      selfCheckOut: "Salida Autónoma",
      preRegistrationSuccess:
        "¡Su proceso de preinscripción ahora está configurado!",
      registrationComplete:
        "¡Acaba de completar la preinscripción!<br /><br />Se le enviará un mensaje con el enlace de su aplicación para viajeros.<br /><br />Tómese el tiempo para revisarlo para familiarizarse con los detalles y prepararse para su llegada.<br /><br />¡Hasta pronto!",
      fillAllFields: "Por favor, complete todos los campos.",
      emailFormatIncorrect: "El formato del correo electrónico es incorrecto",
      hello: "Hola",
      notices: "Guía del Usuario",
      parking: "Aparcamiento",
      goodspots: "Lugares Recomendados",
      confirmAndFinish: "Confirmar y Terminar",
      appOverview:
        "Descripción general de la aplicación para acceder a todos nuestros servicios e información durante su estancia.",
    },
  },
  de: {
    translation: {
      welcome: "Willkommen \u200B <br /> bei MyBusiness-bnb",
      chooseLanguage: "Welche Sprache sprichst du?",
      french: "Französisch",
      english: "Englisch",
      spanish: "Spanisch",
      german: "Deutsch",
      areYouTourist: "Bist du ein Tourist",
      frenchOption: "Französisch",
      foreignerOption: "Ausländer",
      selectedOption: "Ausgewählte Option:",
      conciergeInfo: `
      <div>
      Wir verwenden diese Anwendung, um die Verwaltung unserer Vermietungen zu erleichtern.
    </div>
    
      `,
      conciergeInfo2: "Bitte überprüfen Sie die Richtigkeit der Informationen.",
      conciergeInfo3:
        "Und vielen Dank, dass Sie Ihre Ankunfts- und Abfahrtszeiten angeben.",
      houseName: "Unterkunftsname:",
      reservationDates: "Buchungsdaten:",
      months: {
        1: "Jan.",
        2: "Feb.",
        3: "März",
        4: "Apr.",
        5: "Mai",
        6: "Jun.",
        7: "Jul.",
        8: "Aug.",
        9: "Sep.",
        10: "Okt.",
        11: "Nov.",
        12: "Dez.",
      },
      from: "Vom",
      to: "Bis zum",
      selectBothHours:
        "Bitte wählen Sie sowohl die Ankunfts- als auch die Abfahrtszeiten aus.",
      specifyArrivalDeparture:
        "Geben Sie Ihre Ankunfts- und Abfahrtszeiten an.",
      arrivalHour: "Ankunftszeit",
      departureHour: "Abfahrtszeit",
      numberOfBeds: "Anzahl der Betten",
      selectBeds: "Anzahl der Betten auswählen",
      selectBedsError: "Bitte wählen Sie die Anzahl der Betten aus.",
      requiredInformation:
        "Erforderliche Informationen für Ihren Aufenthalt in Frankreich",
      foreignTravelersInfo:
        "Als ausländische Reisende, die Frankreich besuchen, ist es unerlässlich, ein 'Polizeiformular' mit Ihren persönlichen Informationen auszufüllen. Wenn diese Informationen nicht bereitgestellt werden, wird leider der Zugang zur reservierten Unterkunft verweigert.",
      personalInfo: "Persönliche Informationen",
      firstName: "Vorname",
      lastName: "Nachname",
      birthDate: "Geburtsdatum",
      birthDateFormat: "dd.mm.yyyy",
      placeOfBirth: "Geburtsort",
      nationality: "Nationalität",
      personalAddress: "Ihre persönliche Adresse",
      country: "Land",
      streetAddress: "Adresse",
      apartmentBuilding: "Wohnung, Gebäude",
      postalCode: "Postleitzahl",
      city: "Stadt",
      communicationMethod: "Kommunikationsmethode",
      phone: "Telefon",
      email: "Email",
      invalidEmail: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
      informationTransmission:
        "Diese Informationen ermöglichen es uns, Ihnen die notwendigen Anweisungen für Ihre Ankunft vor Ort zu geben.",
      myBusinessBnbGuide:
        "MyBusiness-bnb Die App, die Sie während Ihres Aufenthalts führen wird.",
      appDescription:
        "Diese Anwendung erleichtert die Kommunikation zwischen Gastgebern und Reisenden während des gesamten Aufenthalts.",
      address: "Adresse",
      selfCheckIn: "Selbstständiger Check-In",
      contacts: "Kontakte",
      wifiCode: "WLAN-Passwort",
      trash: "Müll",
      selfCheckOut: "Selbstständiger Check-Out",
      preRegistrationSuccess: "Ihr Voranmeldeprozess ist jetzt eingerichtet!",
      registrationComplete:
        "Sie haben gerade die Voranmeldung abgeschlossen!<br /><br />Eine Nachricht mit dem Link zu Ihrer Reiseanwendungs-App wird Ihnen zugesandt.<br /><br />Nehmen Sie sich die Zeit, es zu überprüfen, um sich mit den Details vertraut zu machen und sich auf Ihre Ankunft vorzubereiten.<br /><br />Bis bald!",
      fillAllFields: "Bitte füllen Sie alle Felder aus.",
      emailFormatIncorrect: "Das E-Mail-Format ist inkorrekt",
      hello: "Hallo",
      notices: "Benutzerhandbuch",
      parking: "Parkplatz",
      goodspots: "Gute Orte",
      confirmAndFinish: "Bestätigen und Abschließen",
      appOverview:
        "Übersicht über die App, um auf alle unsere Dienstleistungen und Informationen während Ihres Aufenthalts zuzugreifen.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr", // Langue par défaut
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
