import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SlLocationPin } from "react-icons/sl";
import { GiCarKey } from "react-icons/gi";
import { MdOutlineWifiPassword } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { GrContactInfo } from "react-icons/gr";
import ClientAppAddress from "./ClientAppAddress";
import ClientAppWifi from "./ClientAppWifi";
import ClientAppContact from "./ClientAppContact";
import ClientAppGenericSelfCheckIn from "./ClientAppGeneric";
import ClientAppGenericSelfCheckOut from "./ClientAppGeneric";
import ClientAppGenericManual from "./ClientAppGeneric";
import ClientAppGenericTrash from "./ClientAppGeneric";
import ClientAppGenericGoodSpots from "./ClientAppGeneric";
import ClientAppGenericParking from "./ClientAppGeneric";
import { useDataContext } from "../Context/DataContext";
import HttpService from "../Services/HttpService";
import GTranslateSettings from "./GTranslateSettings";
import { TfiBook } from "react-icons/tfi";
import { RiRestaurantFill } from "react-icons/ri";
import { RiParkingFill } from "react-icons/ri";

const styles = {
  borderRadius: "50%",
  width: "70px",
  height: "70px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
};

const contactBackgroundColor = {
  ...styles,
  backgroundColor: "#E86F2B",
};

const mapsBackgroundColor = {
  ...styles,
  backgroundColor: "#8E44AF",
};

const checkInBackgroundColor = {
  ...styles,
  backgroundColor: "#2980B9",
};

const wifiInBackgroundColor = {
  ...styles,
  backgroundColor: "#29BB66",
};

const trashBackgroundColor = {
  ...styles,
  backgroundColor: "#F39C11",
};

const checkOutBackgroundColor = {
  ...styles,
  backgroundColor: "#FC3465",
};

const manualBackgroundColor = {
  ...styles,
  backgroundColor: "#989898",
};

const parkingBackgroundColor = {
  ...styles,
  backgroundColor: "#68033E",
};

const goodSpotsBackgroundColor = {
  ...styles,
  backgroundColor: "#C18BBF",
};

function IntroToMBApp({ clientApp }) {
  const { t, i18n } = useTranslation();
  const [showClientAppAddress, setShowClientAppAddress] = useState(false);
  const [showClientAppWifi, setShowClientAppWifi] = useState(false);
  const [showClientAppContact, setShowClientAppContact] = useState(false);
  const [showClientAppManual, setShowClientAppManual] = useState(false);
  const [showClientAppParking, setShowClientAppParking] = useState(false);
  const [showClientAppGoodSpots, setShowClientAppGoodSpots] = useState(false);
  const [showClientAppGenericSelfCheckIn, setShowClientAppGenericSelfCheckIn] =
    useState(false);
  const [showClientAppGenericTrash, setShowClientAppGenericTrash] =
    useState(false);
  const [
    showClientAppGenericSelfCheckOut,
    setShowClientAppGenericSelfCheckOut,
  ] = useState(false);
  const [showClientAppAvalaible, setShowClientAppAvalaible] = useState(false);
  const { propertyData, setPropertyData } = useDataContext();
  const [loading, setLoading] = useState(true);
  const [guideType, setGuideType] = useState();
  const [overlayMessage, setOverlayMessage] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);

  const handleOverlayClose = () => {
    setOverlayMessage("");
  };

  const displayOverlayMessage = (message) => {
    setOverlayMessage(message);
  };

  const highlightTextInRed = (inputString) => {
    const targetText = "MyBusiness-bnb";
    if (inputString.includes(targetText)) {
      const styledString = inputString.replace(
        new RegExp(targetText, "g"),
        `<span style="color: #FF1654;">${targetText}</span>`
      );
      return styledString;
    } else {
      return inputString;
    }
  };

  const redirectToClientAppAddress = (guideType) => {
    setGuideType(guideType);
    setShowClientAppAddress(true);
  };

  const redirectToClientAppWifi = (guideType) => {
    setGuideType(guideType);
    setShowClientAppWifi(true);
  };

  const redirectToClientAppContact = (guideType) => {
    setGuideType(guideType);
    setShowClientAppContact(true);
  };

  const redirectToClientAppGenericSelfCheckIn = (guideType) => {
    setGuideType(guideType);
    setShowClientAppGenericSelfCheckIn(true);
  };

  const redirectToClientAppGenericTrash = (guideType) => {
    setGuideType(guideType);
    setShowClientAppGenericTrash(true);
  };

  const redirectToClientAppGenericSelfCheckOut = (guideType) => {
    setGuideType(guideType);
    setShowClientAppGenericSelfCheckOut(true);
  };

  const redirectToClientAppGenericManual = (guideType) => {
    setGuideType(guideType);
    setShowClientAppManual(true);
  };

  const redirectToClientAppGenericGoodSpots = (guideType) => {
    setGuideType(guideType);
    setShowClientAppGoodSpots(true);
  };

  const redirectToClientAppGenericParking = (guideType) => {
    setGuideType(guideType);
    setShowClientAppParking(true);
  };

  const handleBack = () => {
    setShowClientAppAddress(false);
    setShowClientAppWifi(false);
    setShowClientAppContact(false);
    setShowClientAppGenericSelfCheckIn(false);
    setShowClientAppGenericSelfCheckOut(false);
    setShowClientAppGenericTrash(false);
    setShowClientAppManual(false);
    setShowClientAppGoodSpots(false);
    setShowClientAppParking(false);
  };

  useEffect(() => {
    const mBKEY = propertyData.param1;
    const bookId = propertyData.param2;
    if (clientApp) {
      HttpService.get(`guide/guest/${mBKEY}/${bookId}`)
        .then((data) => {
          if (data && data.guideDetails) {
            setPropertyData({ ...propertyData, data: data });
            setShowClientAppAvalaible(true);
          } else if (data && data.appAvailableDateFrom) {
            clientApp = false;
            displayOverlayMessage(
              "Application disponible à partir du " + data.appAvailableDateFrom
            );
          } else {
            clientApp = false;
            displayOverlayMessage(
              "Application pas encore disponible pour votre voyage."
            );
          }
        })
        .catch((error) => {
          console.error("GET Error:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when the request is completed
        });
    }
  }, []);

  return (
    <div className="mt-4">
      {clientApp && (
        <div className="d-flex align-items-center justify-content-center">
          <GTranslateSettings />
          <br />
          <br />
        </div>
      )}
      {overlayMessage && (
        <div className="overlay">
          <div className="overlay-content">
            <p>{overlayMessage}</p>
            <button
              style={{
                backgroundColor: "#2980B9",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleOverlayClose}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {showClientAppAddress ? (
        <ClientAppAddress guideTypeTitle={guideType} onBack={handleBack} />
      ) : showClientAppGenericSelfCheckIn ? (
        <ClientAppGenericSelfCheckIn
          guideTypeTitle={guideType}
          guideType="1"
          onBack={handleBack}
        />
      ) : showClientAppGenericSelfCheckOut ? (
        <ClientAppGenericSelfCheckOut
          guideTypeTitle={guideType}
          guideType="2"
          onBack={handleBack}
        />
      ) : showClientAppGenericTrash ? (
        <ClientAppGenericTrash
          guideTypeTitle={guideType}
          guideType="3"
          onBack={handleBack}
        />
      ) : showClientAppContact ? (
        <ClientAppContact guideTypeTitle={guideType} onBack={handleBack} />
      ) : showClientAppWifi ? (
        <ClientAppWifi guideTypeTitle={guideType} onBack={handleBack} />
      ) : showClientAppManual ? (
        <ClientAppGenericManual
          guideTypeTitle={guideType}
          guideType="4"
          onBack={handleBack}
        />
      ) : showClientAppGoodSpots ? (
        <ClientAppGenericGoodSpots
          guideTypeTitle={guideType}
          guideType="6"
          onBack={handleBack}
        />
      ) : showClientAppParking ? (
        <ClientAppGenericParking
          guideTypeTitle={guideType}
          guideType="5"
          onBack={handleBack}
        />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h4
              style={{
                textAlign: "center",
              }}
              dangerouslySetInnerHTML={{
                __html: highlightTextInRed(t("myBusinessBnbGuide")),
              }}
            ></h4>
          </div>
          {!clientApp && (
            <div style={{ color: "rgb(255, 193, 7)", textAlign: "center" }}>
              {t("appOverview")}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateRows: "auto auto", // Set rows to "auto auto"
                gridTemplateColumns: "repeat(3, 1fr)", // Set columns to "1fr 1fr"
                rowGap: "20px",
                columnGap: "50px",
                margin: "20px 0",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  cursor:
                    clientApp && showClientAppAvalaible ? "pointer" : "auto",
                }}
                onClick={
                  clientApp && showClientAppAvalaible
                    ? () => redirectToClientAppGenericParking("Parking")
                    : null
                }
              >
                <div style={{ ...parkingBackgroundColor, ...styles }}>
                  <RiParkingFill style={{ fontSize: "30px" }} />
                </div>
                <span>{t("parking")}</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  cursor:
                    clientApp && showClientAppAvalaible ? "pointer" : "auto",
                }}
                onClick={
                  clientApp && showClientAppAvalaible
                    ? () => redirectToClientAppAddress("Adresse")
                    : null
                }
              >
                <div style={{ ...mapsBackgroundColor, ...styles }}>
                  <SlLocationPin style={{ fontSize: "40px" }} />
                </div>
                <span>{t("address")}</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  cursor:
                    clientApp && showClientAppAvalaible ? "pointer" : "auto",
                }}
                onClick={
                  clientApp && showClientAppAvalaible
                    ? () =>
                        redirectToClientAppGenericSelfCheckIn(
                          "Arrivée autonome"
                        )
                    : null
                }
              >
                <div style={{ ...checkInBackgroundColor, ...styles }}>
                  <GiCarKey style={{ fontSize: "40px" }} />
                </div>
                <span>{t("selfCheckIn")}</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  cursor:
                    clientApp && showClientAppAvalaible ? "pointer" : "auto",
                }}
                onClick={
                  clientApp && showClientAppAvalaible
                    ? () => redirectToClientAppContact("Personne à contacter")
                    : null
                }
              >
                <div style={{ ...contactBackgroundColor, ...styles }}>
                  <GrContactInfo style={{ fontSize: "40px" }} />
                </div>
                <span>{t("contacts")}</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  cursor:
                    clientApp && showClientAppAvalaible ? "pointer" : "auto",
                }}
              >
                <div
                  style={{ ...wifiInBackgroundColor, ...styles }}
                  onClick={
                    clientApp && showClientAppAvalaible
                      ? () => redirectToClientAppWifi("Code Wifi")
                      : null
                  }
                >
                  <MdOutlineWifiPassword style={{ fontSize: "40px" }} />
                </div>
                <span>{t("wifiCode")}</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  cursor:
                    clientApp && showClientAppAvalaible ? "pointer" : "auto",
                }}
                onClick={
                  clientApp && showClientAppAvalaible
                    ? () => redirectToClientAppGenericTrash("Poubelle")
                    : null
                }
              >
                <div style={{ ...trashBackgroundColor, ...styles }}>
                  <FaTrashAlt style={{ fontSize: "30px" }} />
                </div>
                <span>{t("trash")}</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  cursor:
                    clientApp && showClientAppAvalaible ? "pointer" : "auto",
                }}
                onClick={
                  clientApp && showClientAppAvalaible
                    ? () =>
                        redirectToClientAppGenericSelfCheckOut(
                          "Départ autonome"
                        )
                    : null
                }
              >
                <div style={{ ...checkOutBackgroundColor, ...styles }}>
                  <FiLogOut style={{ fontSize: "30px" }} />
                </div>
                <span>{t("selfCheckOut")}</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  cursor:
                    clientApp && showClientAppAvalaible ? "pointer" : "auto",
                }}
                onClick={
                  clientApp && showClientAppAvalaible
                    ? () => redirectToClientAppGenericManual("Mode d'emploi")
                    : null
                }
              >
                <div style={{ ...manualBackgroundColor, ...styles }}>
                  <TfiBook style={{ fontSize: "30px" }} />
                </div>
                <span>{t("notices")}</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  cursor:
                    clientApp && showClientAppAvalaible ? "pointer" : "auto",
                }}
                onClick={
                  clientApp && showClientAppAvalaible
                    ? () => redirectToClientAppGenericGoodSpots("Bons Plans")
                    : null
                }
              >
                <div style={{ ...goodSpotsBackgroundColor, ...styles }}>
                  <RiRestaurantFill style={{ fontSize: "30px" }} />
                </div>
                <span>{t("goodspots")}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default IntroToMBApp;
